<template>
  <div id="wishcomp">
    <section class="wishlist-wrap pt-100 pb-75">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="wishlist-table">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Total Price</th>
                    <th scope="col">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="wh_item">
                        <img :src="thumb1" alt="Image" />
                        <div class="wh-item-info">
                          <a href="#">Chicken Sandwitch</a>
                          <p>
                            There are many variation of chicken.You can choose your
                            favourite one
                          </p>
                          <span>$32.00</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="wh_qty">
                        <div class="product-quantity style2">
                          <div class="qtySelector">
                            <span class="las la-minus decreaseQty"></span>
                            <input type="text" class="qtyValue" value="1" />
                            <span class="las la-plus increaseQty"></span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="wh-tem-price">$32.00</p>
                    </td>
                    <td>
                      <button type="button"><i class="las la-times"></i></button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="wh_item">
                        <img :src="thumb2" alt="Image" />
                        <div class="wh-item-info">
                          <a href="#">Strawbery Salsa </a>
                          <p>
                            There are many variation of chicken.You can choose your
                            favourite one
                          </p>
                          <span>$43.00</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="wh_qty">
                        <div class="product-quantity style2">
                          <div class="qtySelector">
                            <span class="las la-minus decreaseQty"></span>
                            <input type="text" class="qtyValue" value="1" />
                            <span class="las la-plus increaseQty"></span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="wh-tem-price">$43.00</p>
                    </td>
                    <td>
                      <button type="button"><i class="las la-times"></i></button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="wh_item">
                        <img :src="thumb3" alt="Image" />
                        <div class="wh-item-info">
                          <a href="#">Pasta Barista</a>
                          <p>
                            There are many variation of chicken.You can choose your
                            favourite one
                          </p>
                          <span>$22.00</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="wh_qty">
                        <div class="product-quantity style2">
                          <div class="qtySelector">
                            <span class="las la-minus decreaseQty"></span>
                            <input type="text" class="qtyValue" value="1" />
                            <span class="las la-plus increaseQty"></span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="wh-tem-price">$22.00</p>
                    </td>
                    <td>
                      <button type="button"><i class="las la-times"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "wishcomp",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      thumb1: require("@/assets/img/blog/post-thumb-1.jpg"),
      thumb2: require("@/assets/img/blog/post-thumb-2.jpg"),
      thumb3: require("@/assets/img/blog/post-thumb-3.jpg"),
    };
  },
  components: {
  },
};
</script>
