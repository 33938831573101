var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wishcomp"}},[_c('section',{staticClass:"wishlist-wrap pt-100 pb-75"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"wishlist-table"},[_c('table',{staticClass:"table table-bordered"},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',[_c('div',{staticClass:"wh_item"},[_c('img',{attrs:{"src":_vm.thumb1,"alt":"Image"}}),_vm._m(1)])]),_vm._m(2),_vm._m(3),_vm._m(4)]),_c('tr',[_c('td',[_c('div',{staticClass:"wh_item"},[_c('img',{attrs:{"src":_vm.thumb2,"alt":"Image"}}),_vm._m(5)])]),_vm._m(6),_vm._m(7),_vm._m(8)]),_c('tr',[_c('td',[_c('div',{staticClass:"wh_item"},[_c('img',{attrs:{"src":_vm.thumb3,"alt":"Image"}}),_vm._m(9)])]),_vm._m(10),_vm._m(11),_vm._m(12)])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Product")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Quantity")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Total Price")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Remove")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wh-item-info"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Chicken Sandwitch")]),_c('p',[_vm._v(" There are many variation of chicken.You can choose your favourite one ")]),_c('span',[_vm._v("$32.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"wh_qty"},[_c('div',{staticClass:"product-quantity style2"},[_c('div',{staticClass:"qtySelector"},[_c('span',{staticClass:"las la-minus decreaseQty"}),_c('input',{staticClass:"qtyValue",attrs:{"type":"text","value":"1"}}),_c('span',{staticClass:"las la-plus increaseQty"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('p',{staticClass:"wh-tem-price"},[_vm._v("$32.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('button',{attrs:{"type":"button"}},[_c('i',{staticClass:"las la-times"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wh-item-info"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Strawbery Salsa ")]),_c('p',[_vm._v(" There are many variation of chicken.You can choose your favourite one ")]),_c('span',[_vm._v("$43.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"wh_qty"},[_c('div',{staticClass:"product-quantity style2"},[_c('div',{staticClass:"qtySelector"},[_c('span',{staticClass:"las la-minus decreaseQty"}),_c('input',{staticClass:"qtyValue",attrs:{"type":"text","value":"1"}}),_c('span',{staticClass:"las la-plus increaseQty"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('p',{staticClass:"wh-tem-price"},[_vm._v("$43.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('button',{attrs:{"type":"button"}},[_c('i',{staticClass:"las la-times"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wh-item-info"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Pasta Barista")]),_c('p',[_vm._v(" There are many variation of chicken.You can choose your favourite one ")]),_c('span',[_vm._v("$22.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"wh_qty"},[_c('div',{staticClass:"product-quantity style2"},[_c('div',{staticClass:"qtySelector"},[_c('span',{staticClass:"las la-minus decreaseQty"}),_c('input',{staticClass:"qtyValue",attrs:{"type":"text","value":"1"}}),_c('span',{staticClass:"las la-plus increaseQty"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('p',{staticClass:"wh-tem-price"},[_vm._v("$22.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('button',{attrs:{"type":"button"}},[_c('i',{staticClass:"las la-times"})])])
}]

export { render, staticRenderFns }