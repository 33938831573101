<template>
  <div id="wishlist">
    <Header></Header>
    <div class="content-wrapper">
      <div class="breadcrumb-wrap bg-f br-1">
        <div class="overlay bg-black op-9"></div>
        <img :src="shape1" alt="Image" class="br-shape-1" />
        <img :src="shape2" alt="Image" class="br-shape-2" />
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="breadcrumb-title">
                <h2>Wishlist</h2>
                <ul class="breadcrumb-menu list-style">
                  <li><!--<a href="index-2.html">Home </a>--><router-link to="/">Home </router-link></li>
                  <li>Wishlist</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>     
    </div>
    <WishComp></WishComp>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import WishComp from "@/views/shop/WishComp.vue";
import Footer from "@/views/layouts/Footer.vue";
export default {
  name: "wishlist",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
    };
  },
  components: {
    Header,
    Footer,
    WishComp,
  },
};
</script>
